.ForgetPassword {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  min-width: 500px;
  padding: 20px 15px 70px 15px;
  box-sizing: border-box;
}

.LogoContainer {
  height: 80px;
  box-sizing: border-box;
  margin: 10px 20px 40px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.CompanyLogo {
  width: 210px;
  object-fit: contain;
}

.titleContainer {
  margin: 0px 20px;
}

.loginText {
  color: #444444;
  margin: 20px 0 0 0;
}

.subText {
  color: #8894a0;
  margin: 4px 0 0 0;
  font-size: 12px;
}

.textField {
  display: block;
  width: 100%;
}

.LoginButton{
  width: 100%;
  margin: 20px 0 !important;
}

.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.ErrorText {
  color: #E10050;
}

@media screen and (max-width: 500px) {
  .paper {
    min-width: 100%;
  }
}