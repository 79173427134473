.Footer {
  width: 100%;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8894a0;
  margin: 0 0 5px 0;
}

.FooterLinkText {
  color: #8894a0;
  text-decoration: none;
}