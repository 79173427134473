.NavigationItems {
  width: 100%;
  padding: 0px 0px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
}

.LabelText {
  text-decoration: none !important;
}

.LogoContainer {
  height: 80px;
  box-sizing: border-box;
  margin: 20px 20px 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.CompanyLogo {
  width: 210px;
  object-fit: contain;
}

.active {
  color: #10228B;
}

.MobileCompanyIconContainer {
  width: 60px;
  height: 64px;
  box-sizing: border-box;
  margin: 0 0 0 0;
  /* border-bottom: 1px solid #DFE3E8; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.MobileCompanyLogo {
  width: 40px;
  object-fit: contain;
}

.visible {
  /* border-bottom: 1px solid #DFE3E8; */
  transition: all 0.5s ease;
}

.hidden {
  opacity: 1;
  transition: all 0.5s ease;
}