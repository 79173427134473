:global(.active #ListItem) {
  background-color: rgba(130, 204, 221,0.1);
  border-left: 10px solid #10228B;
}

:global(.active #ListItem #ListItemLabel) {
  color: #444;
  font-weight: bold
}

/* :global(#ListItem:hover) {
  background-color: rgba(130, 204, 221,0.1);
} */