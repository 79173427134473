.Users {
  width: 100%;
  padding: 20px 40px 0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
}

.ToolBar {
  width: 100%;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 20px 0;
}

.buttonRow {
  height: 30px;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}

.footer {
  margin: 30px 0 0 0;
}

@media screen and (max-width: 765px) {
  .Users {
    padding: 50px 10px 0px 10px;
    overflow-y: scroll;
    height: unset;
  }
}