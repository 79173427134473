.PanelLayout {
  width: 100vw;
  height: 100vh;
  background-color: #f9fafb;
  display: flex;
  overflow-y: hidden;
}

.Drawer {
  width: 250px;
  background-color: white;
  border-right: 1px solid #DFE3E8;
  transition: all 0.5s ease;
}

.Hide {
  width: 60px;
  /* transform: translateX(-50px); */
  transition: all 0.5s ease;
  background-color: white;
  border-right: 1px solid #DFE3E8;
}

.Content {
  width: 100%;
  /* height: calc(100% - 64px); */
  /* overflow-y: hidden; */
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.AppBarRoot {
  background-color: white !important;
  color: #12161B !important;
  box-shadow: none !important;
  border-bottom: 1px solid #DFE3E8;
}

.grow {
  flex-grow: 1;
}

.MenuButton {
  margin-left: -12;
  margin-right: 20;
}

.Footer {
  position: absolute;
  bottom: 0;
}

.mobileDrawer {
  display: none;
}

.ToolTip {
  z-index: 600;
}

@media screen and (max-width: 765px) {
  .Hide {
    display: none;
  }
  .mobileDrawer {
    display: block;
  }
}

