.FirmwareTable {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 500px;
}

.TableWrapper {
  overflow-x: auto;
  height: calc(100% - 56px);
}

.TableHead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 500;
}

.chipUnpublish {
  color: #f50057 !important;
  border: 1px solid #f50057 !important;
}

.chipPublish {
  color: #2196f3 !important;
  border: 1px solid #2196f3 !important;
}

@media screen and (max-width: 765px) {
  .TableWrapper {
    min-height: 300px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .UserTable {
    height: 500px;
    min-height: 500px;
  }
}
