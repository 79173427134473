.smallModal {
  transition: all 0.5s ease;
  width: 150px;
  height: 150px;
}

.mediumModal {
  transition: all 0.5s ease;
  width: 400px;
}

.largeModal {
  transition: all 0.5s ease;
  width: 600px;
}

.SuccessModal {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.CheckIcon {
  width: 60px !important;
  height: 60px !important;
  color: #16A085;
}

.ErrorIcon {
  width: 60px !important;
  height: 60px !important;
  color: #E10050;
}

.errorMsg {
  color: #E10050;
}

.SuccessTitleText {
  text-align: center;
}

.ModalTitle {
  font-size: 20px;
  color: #66788A;
  flex: 0 0 auto;
  margin: 0;
  padding: 24px 24px 20px;
}

.show {
  display: block;
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}