.FormInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 117px;
  margin: 0 0 10px 0
}

.TextField {
  width: 100%;
}

.InputField {
  padding: 18px 10px !important;
  color: black!important;
  font-weight: 500!important;
}

.ErrorText {
  color: #E10050;
  margin: 0 0 0 5px;
}

.remarkText {
  color: #66788A;
  margin: 0 0 5px 5px;
  font-size: 12px;
}