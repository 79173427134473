.smallModal {
  transition: all 0.5s ease;
  width: 150px;
  height: 150px;
}

.mediumModal {
  transition: all 0.5s ease;
  width: 400px;
  height: 400px;
}

.largeModal {
  transition: all 0.5s ease;
  width: 600px;
  height: 400px;
}

.SuccessModal {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
}

.flex {
  flex: 1
}

.DropzoneContainer {
  margin: 84px 0 0 0;
  width: 100%;
  height: 70%;
  background-color: white;
  padding: 20px 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dropzone {
  width: 100%;
  height: 100%;
}

.DropArea {
  width: 100%;
  height: 100%;
  border: 4px dotted #eee;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DropArea:focus {
  outline: none;
}

.UploadIcon {
  width: 100px !important;
  height: 100px !important;
  color: #DDD;
}

.dropText {
  color: #DDD;
  font-size: 20px;
  font-weight: 600;
}

.ModalTitle {
  font-size: 20px;
  color: #66788A;
  flex: 0 0 auto;
  margin: 0;
  padding: 24px 24px 20px;
}

.FileContainer {
  padding-left: 50px;
}