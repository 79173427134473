.Verify {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.searchPaper {
  min-width: 500px;
  padding: 20px 15px 70px 15px;
  box-sizing: border-box;
}

.textColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerText {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
}

.subHeaderText {
  font-size: 30px;
  font-weight: 700;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .Verify {
    height: 100%;
  }
  .searchPaper {
    box-shadow: none !important;
    min-width: unset !important;
  }
}