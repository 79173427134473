.ListItem {
  width: 100%;
  padding: 11px 16px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.labelText {
  text-decoration: none;
  color: #66788A;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.hidden {
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

